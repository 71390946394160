import React, { useEffect } from 'react'
import { Flex, useColorMode } from 'theme-ui'

export const KlaviyoPage = () => {
  const [_, setColorMode] = useColorMode()
  useEffect(() => {
    setColorMode('default-product-1')
  })
  return (
    <Flex
      sx={{
        width: '100%',
        minHeight: '75vh',
        py: '8rem',
        px: ['1rem', '1rem', '5rem'],
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div class="klaviyo-form-U4bU6x"></div>
    </Flex>
  )
}
