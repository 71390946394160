import React from 'react'
import { KlaviyoPage } from '~/components/Klaviyo/KlaviyoPage'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'

const ExpoPartyRequest = () => {
  return (
    <Layout>
      <Metadata title="Klaviyo" />
      <KlaviyoPage />
    </Layout>
  )
}

export default ExpoPartyRequest
